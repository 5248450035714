import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['subject', 'otherContainer'];

  connect() {
    this.checkSubject();
  }

  checkSubject() {
    const selectedOption = this.subjectTarget.options[this.subjectTarget.selectedIndex];
    const subjectId = selectedOption.value;

    const subjectOtherField = this.otherContainerTarget.querySelector('input');

    if (subjectId === '1') {
      this.otherContainerTarget.classList.remove('tw-hidden');
      this.otherContainerTarget.classList.add('tw-flex');
      subjectOtherField.setAttribute('required', 'required');
    } else {
      this.otherContainerTarget.classList.remove('tw-flex');
      this.otherContainerTarget.classList.add('tw-hidden');
      subjectOtherField.removeAttribute('required');
    }
  }
}
